//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';
import styles from './DialogBox.module.css'
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import quizAiLogo from '../images/logoQuizAI.png';
import image from '../images/imagepopup.jpg';
import { set } from 'date-fns';

function DialogBox({ questionDialog, optionsDialog, isopen, action, actionScript, showImage, index, indexChoice, actualFolder, foldersMove, changeSubtitleLanguage = () => { }, langSubtitle }) {

  const { preferenceLanguage } = useLanguageValue();
  const { t } = useTranslation(['dialogbox']);

  const [modalIsOpen, setModalIsOpen] = useState(isopen);
  const [optionClicked, setOptionClicked] = useState("");
  const [newName, setNewName] = useState();
  const [folder, setFolder] = useState(actualFolder);
  const [originalLanguage, setOriginalLanguage] = useState("Portuguese");
  const [destinyLanguage, setDestinyLanguage] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [destinySubLanguages, setDestinySubLanguages] = useState([]);
  const [showMessageAgain, setShowMessageAgain] = useState(true);

  const handleCloseModal = (option) => {
    setModalIsOpen(false)
    var choice = false;
    if (option === "Excluir") {
      choice = true
    }
    switch (action) {
      case "delete":
        return handleDelete(option)
      case "move":
        return handleCloseMove()
      case "rename":
        return handleRename()
      case "close_rename_folder":
        return handleClose()
      case "change_subtitle" || "video_convert":
        return handleLanguageChoiced(option);
      case "cancelplan":
        return handleCancelPlan(option);
      case "reactiveplan":
        return handleReactivePlan(option);
      case "translate_subtitle":
        return handleClose(option)
      case "translate_project":
          return handleClose(option, destinyLanguage)
      default:
        return handleClose()
    }
  };

  const handleLanguageChoiced = () => {
    actionScript()
    setModalIsOpen(false);
  }

  const handleCancelPlan = (option) => {
    actionScript("", option)
    setModalIsOpen(false);
  }

  const handleReactivePlan = (option) => {
    actionScript("", option)
    setModalIsOpen(false);
  }

  const handleClose = (option, language) => {
    actionScript(option, language)
    setModalIsOpen(false);
  }

  const handleRename = (event) => {
    actionScript(newName, false);
    setModalIsOpen(false);
  };

  const handleCancelRename = () => {
    actionScript(newName, true);
    setModalIsOpen(false);
  }

  const handleCloseMove = () => {
    actionScript(folder, false);
    setModalIsOpen(false);
  };

  const handleCancelMove = () => {
    actionScript(folder, true);
    setModalIsOpen(false);
  }

  const handleDelete = (option) => {
    const confirm = option
    const confirmed = (confirm != "Cancelar" && confirm != "Cancel") ? true : false;
    actionScript(confirmed, index, indexChoice);
  };

  const languageMap = {
    'Português': 'pt-BR',
    'Inglês': 'en-US',
    'Espanhol': 'es-ES',
    'Francês': 'fr-FR',
    'Alemão': 'de-DE',
    'Italiano': 'it-IT',
    'Chinês': 'zh-CN',
    'Russo': 'ru-RU',
    'Japonês': 'ja-JP',
    'Coreano': 'ko-KR'
  };

  useEffect(() => {
    if (langSubtitle) {
      const updatedLanguageMap = Object.fromEntries(
        Object.entries(languageMap).filter(([key, value]) => value !== langSubtitle)
      );
      setDestinySubLanguages(updatedLanguageMap);
    } else {
      setDestinySubLanguages(languageMap);
    }
  }, [langSubtitle])

  useEffect(() => {
    if (originalLanguage || destinyLanguage) {
      changeSubtitleLanguage(originalLanguage, destinyLanguage)
    }
  }, [originalLanguage, destinyLanguage]);

  const handleShowMessageAgain = (showAgain) => {
    setShowMessageAgain(showAgain);
    localStorage.setItem('showMessageAgain', !showAgain);
  };


  return (
    <div>
      <Modal show={modalIsOpen} onHide={handleCloseModal} backdrop="static" centered>
        <Modal.Header >
          <img src={quizAiLogo} alt="QuizAI" className={styles.logo} />
        </Modal.Header>
        <Modal.Body>
          {showImage && <div className={`d-flex justify-content-center`}>
            <img src={image} className={`mb-3 img-fluid ${styles.image}`} alt="image"></img>
          </div>}
          {action === "askingIA"
            ? <div className='d-flex align-items-center justify-content-center flex-column'>
              <div className={`col-6 text-center`}>
                <div className="spinner-grow text-primary" role="status"></div>
                <h5 className={`mt-1 ${styles.questionDialog}`}>
                  {`${questionDialog}`}
                </h5>
              </div>
            </div>
            : <h4 className={`${styles.questionDialog}`} ><div dangerouslySetInnerHTML={{ __html: questionDialog }} /></h4>
          }
          {(action === "change_subtitle" || action === "video_convert" || action === "translate_subtitle" || action === "translate_project") &&
            <div>
              <div className="row d-flex align-items-center mt-1">
                {action != "translate_subtitle" && action != "translate_project" &&
                  <div className="col-12">
                    <h6 className="col-12 ">{t('dialogbox:common.Idioma origem')} </h6>
                    <select
                      className={styles.form_parameters}
                      disabled={disableForm}
                      name="language"
                      value={originalLanguage}
                      onChange={(e) => setOriginalLanguage(e.target.value)}>
                      <option value="">{t('dialogbox:common.Selecione uma opção')}</option>
                      {Object.keys(languageMap).map(language => (
                        <option
                          key={language}
                          value={languageMap[language]}
                          disabled={languageMap[language] === langSubtitle}
                          defaultValue={preferenceLanguage === language}
                        >
                          {t(`dialogbox:common.${language}`)}
                        </option>
                      ))}
                    </select>
                  </div>}
                {(action === "change_subtitle" || action === "translate_subtitle" || action === "translate_project") && <div>
                  <h6 className={`col-12 ${action === "translate_subtitle" || action === "translate_project" ? `mt-0` : `mt-3`}`}>
                    {action === "translate_project"
                      ? t('dialogbox:common.Selecione o idioma')
                      : t('dialogbox:common.Idioma destino')} </h6>
                  <div className="col-12">
                    <select
                      className={styles.form_parameters}
                      disabled={disableForm}
                      name="language"
                      value={destinyLanguage}
                      onChange={(e) => setDestinyLanguage(e.target.value)}>
                      <option value="">{t('dialogbox:common.Selecione uma opção')}</option>
                      {Object.keys(destinySubLanguages).map(language => (
                        <option
                          key={language}
                          value={destinySubLanguages[language]}
                          defaultValue={preferenceLanguage === language}
                        >
                          {t(`dialogbox:common.${language}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>}
              </div>
            </div>}
          {action === "rename" &&
            <label>
              <p></p>
              <input
                className={`${styles.form_rename}`}
                type="text"
                required
                name="newName"
                placeholder={t("dialogbox:common.Digite o novo nome")}
                value={newName}
                onChange={(e) => { setNewName(e.target.value) }}
              />
            </label>}
          {action === "move" &&
            <select
              className={`${styles.form_move}`}
              required
              name="folder"
              value={folder}
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue === "Raíz") {
                  setFolder("Folder");
                } else if (selectedValue === "" || selectedValue === `${t("dialogbox:common.Selecione a pasta")}`) {
                  setFolder(actualFolder);
                } else {
                  setFolder(selectedValue);
                }
              }}
            >
              <option selected>`${t("dialogbox:common.Selecione a pasta")}`</option>
              <option value="Folder">Raíz</option>
              {foldersMove.map((folder) => (
                <option key={folder} value={folder}>
                  {folder}
                </option>
              ))}
            </select>}
        </Modal.Body>
        <Modal.Footer>

          {action != "askingIA" && <ul className="list-unstyled d-flex justify-content-between">
            {optionsDialog.map(option => (
              <li key={option}>
                <button
                  value={option}
                  onClick={() => handleCloseModal(option)}
                  className={`ms-3 ${(option === "Cancelar" || option === "Cancel") ? styles.btn_cancel : styles.btn_style}`}
                  data-bs-dismiss="modal"
                  key={option}>
                  {option}
                </button>
              </li>
            ))}
          </ul>}

          <div className={`${styles.fullWidth}`}>
            {action === "infos_project" && (
              <div className={`form-check `}>
                <input
                  className={`form-check-input ${styles.checkBox_style}`}
                  type="checkbox"
                  id="showMessageAgain"
                  onChange={(e) => handleShowMessageAgain(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="showMessageAgain">
                  <h5 className={`${styles.label_style}`}>{t("dialogbox:common.Não mostrar esta mensagem novamente")}</h5>
                </label>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DialogBox;