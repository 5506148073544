//Translation
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import styles from './VideoInteraction.module.css'
//React Hooks
import { useEffect, useState } from 'react';
//Dev Hooks
import { useLanguageValue } from '../context/LanguageContext';

const VideoInteraction = () => {
    const { t } = useTranslation(['projectpreview']);
    const { preferenceLanguage } = useLanguageValue();

    const [askingIA, setAskingIA] = useState(false);
    const [interactionType, setInteractionType] = useState("question");
    const [msgText, setMsgText] = useState("");

    const changeLanguage = (language) => {
        localStorage.setItem('preferenceLanguage', language);
        i18n.changeLanguage(language);
    };

    const handleDirectionClick = () => {
        setMsgText(t('projectpreview:videointeraction.Gerando interação'));
        setAskingIA(true);
    }

    useEffect(() => {
        if (preferenceLanguage) {
            var language = "en";
            switch (preferenceLanguage) {
                case "Português":
                    language = "pt"
                    break;
                case "Espanhol":
                    language = "es"
                    break;
                case "Inglês":
                    language = "en"
                    break;
            }
            changeLanguage(language);
            i18n.changeLanguage(language);
        }
    }, [preferenceLanguage])

    return (
        <div>
            {askingIA ? (
                <div className='d-flex align-items-center justify-content-center flex-column'>
                    <div className="col-6 text-center mt-5">
                        <div className="spinner-grow text-primary" role="status"></div>
                        <h5 className="mt-3">
                            {`${msgText}`}
                        </h5>
                    </div>
                </div>
            ) : (
                <div className="p-0 m-0">
                    <h6 className={`m-3`}>{t('projectpreview:videointeraction.indique abaixo os parâmetros para a interação')}</h6>
                    <div className="pt-4 ms-3 mt-2">
                        <div className="row align-items-center">
                            <label><h6>{t('projectpreview:videointeraction.Que tipo de interação deseja criar')}</h6></label>
                            <div>
                                <div className="form-check">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value="text"
                                        checked={interactionType === "question"}
                                        onChange={() => setInteractionType("question")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Quiz')}</h6></label>
                                </div>
                            </div>
                            <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value=""
                                        checked={interactionType === "summary"}
                                        onChange={() => setInteractionType("summary")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Resumo')}</h6></label>
                                </div>
                            </div>
                            <div>
                                <div className="form-check mt-1">
                                    <input
                                        className={`form-check-input ${styles.option_check}`}
                                        type="radio"
                                        name="meu_radio"
                                        value=""
                                        checked={interactionType === "content"}
                                        onChange={() => setInteractionType("content")}
                                    />
                                    <label className="form-check-label"><h6>{t('projectpreview:videointeraction.Conteúdo')}</h6></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className={`mt-5 ms-3 mb-4 ${styles.button_quizz} ${styles.btn_style}`} onClick={() => handleDirectionClick()}>
                            {`${t('projectpreview:videointeraction.Gerar interação')}`}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoInteraction;